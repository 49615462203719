import { db, auth } from './firebaseConfig';
import { doc, getDoc, setDoc, updateDoc, onSnapshot, collection, query, where, getDocs, addDoc } from 'firebase/firestore';

// Function to create or update user settings
export const createOrUpdateUserSettings = async (
  userId: string,
  settings: Partial<{ darkMode: boolean }> = {},
  visibleCalendars: string[] = [],
  calendarOrder: string[] = [],
  calendarColors: { [key: string]: string } = {}
) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    const email = auth.currentUser?.email || '';
    const defaultName = email.split('@')[0]; // Käytetään sähköpostin etuliitettä oletusnimenä

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Yhdistetään nykyiset värit ja uudet värit
      const updatedCalendarColors = {
        ...userData.calendarColors, // Säilytetään olemassa olevat värit
        ...calendarColors, // Päivitetään uudet värit
      };

      await updateDoc(userDocRef, {
        lastLogin: new Date(),
        settings: {
          ...userData.settings,
          ...settings,
        },
        visibleCalendars: visibleCalendars.length > 0 ? visibleCalendars : userData.visibleCalendars || [],
        calendarOrder: calendarOrder.length > 0 ? calendarOrder : userData.calendarOrder || [],
        calendarColors: updatedCalendarColors, // Päivitetyt värit
        lastAction: new Date(),
      });
    } else {
      await setDoc(userDocRef, {
        lastLogin: new Date(),
        publicName: defaultName || 'User',
        settings: {
          darkMode: settings.darkMode ?? false,
        },
        visibleCalendars: visibleCalendars.length > 0 ? visibleCalendars : [],
        calendarOrder: calendarOrder.length > 0 ? calendarOrder : [],
        calendarColors: calendarColors, // Uudet värit tallennetaan
        lastAction: new Date(),
      });
    }
  } catch (error) {
    console.error('Error creating or updating user settings:', error);
    throw error;
  }
};


// Function to get user data
export const getUserData = async (userId: string) => {
  console.log('Fetching user data...');
  const userDocRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userDocRef);

  return userDoc.exists() ? userDoc.data() || {} : {};
};

// Function to listen to user data in real-time
export const listenToUserData = (userId: string, callback: (userData: any) => void) => {
  const userDocRef = doc(db, 'users', userId);

  const unsubscribe = onSnapshot(userDocRef, (doc) => {
    if (doc.exists()) {
      const userData = doc.data();
      callback(userData);
    } else {
      callback({});
    }
  });

  return unsubscribe;
};

// Function to update user settings
export const updateUserSettings = async (userId: string, updatedSettings: any) => {
  const userDocRef = doc(db, 'users', userId);
  await updateDoc(userDocRef, {
    settings: updatedSettings,
    lastLogin: new Date(),
  });
};

// Update user name in Firestore
export const updateUserName = async (userId: string, newName: string) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, {
      publicName: newName,
      lastAction: new Date()
    });
    console.log('User name updated');
  } catch (error) {
    console.error('Error updating user name:', error);
    throw error;
  }
};

// Update dark mode setting
export const updateDarkMode = async (userId: string, darkMode: boolean) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, {
      'settings.darkMode': darkMode,
      lastAction: new Date()
    });
    console.log('Dark mode updated');
  } catch (error) {
    console.error('Error updating dark mode:', error);
    throw error;
  }
};

// Update user's visible calendars in Firestore
export const updateUserVisibleCalendars = async (
  userId: string,
  visibleCalendars: string[],
  calendarColors: { [key: string]: string } = {}
) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Yhdistä olemassa olevat värit ja uudet värit
      const updatedCalendarColors = {
        ...userData.calendarColors, // Säilytetään vanhat värit
        ...calendarColors, // Lisätään tai päivitetään uusilla arvoilla
      };

      await updateDoc(userDocRef, {
        visibleCalendars: visibleCalendars,
        calendarColors: updatedCalendarColors, // Päivitetyt värit
        lastAction: new Date(),
      });
      console.log('Visible calendars and calendar colors updated');
    } else {
      console.error('User does not exist. Cannot update visible calendars.');
    }
  } catch (error) {
    console.error('Error updating visible calendars or calendar colors:', error);
    throw error;
  }
};

// Function to fetch user names based on userId list
export const fetchUserNames = async (userIds: string[]) => {
  try {
    const usersQuery = query(collection(db, 'users'), where('userId', 'in', userIds));
    const usersSnapshot = await getDocs(usersQuery);

    return usersSnapshot.docs.map(doc => ({
      userId: doc.id,
      name: doc.data().publicName || 'Unknown'
    }));
  } catch (error) {
    console.error("Error fetching user names:", error);
    throw error;
  }
};

// Function to fetch a single user's name by their ID
export const fetchUserNameById = async (userId: string): Promise<string> => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData?.publicName || 'Unknown User';
    } else {
      console.error(`No user found for ID: ${userId}`);
      return 'Unknown User';
    }
  } catch (error) {
    console.error('Error fetching user name:', error);
    return 'Error';
  }
};

// Log user or calendar action
export const logAction = async (
  targetType: 'user' | 'calendar', // Tyyppi, onko kyseessä käyttäjä vai kalenteri
  userId: string,
  userName: string,
  actionType: 'added' | 'modified' | 'deleted',
  calendarId: string,
  calendarName: string | null, // Kalenterin nimi voi olla null, jos se ei ole käytettävissä
  oldValue: any,
  newValue: any
) => {
  try {
    let actionDocRef;

    if (targetType === 'user') {
      const userActionsRef = collection(db, `users/${userId}/userActions`);
      actionDocRef = await addDoc(userActionsRef, {
        userId,
        userName,
        actionType,
        calendarId,
        calendarName: calendarName || '',
        oldValue,
        newValue,
        timestamp: new Date(),
      });
    } else if (targetType === 'calendar') {
      const calendarActionsRef = collection(db, `calendars/${calendarId}/calendarActions`);
      actionDocRef = await addDoc(calendarActionsRef, {
        userId,
        userName,
        actionType,
        oldValue,
        newValue,
        timestamp: new Date(),
      });
    }

    if (actionDocRef) {
      console.log(`${targetType.charAt(0).toUpperCase() + targetType.slice(1)} action logged with ID: ${actionDocRef.id}`);
    } else {
      console.error(`Failed to log ${targetType} action: actionDocRef is undefined`);
    }
  } catch (error) {
    console.error(`Error logging ${targetType} action:`, error);
    throw error;
  }
};

// Update user's calendar order
export const updateUserCalendarOrder = async (userId: string, calendarId: string) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      let calendarOrder = userData.calendarOrder || [];

      // Move calendarId to the top of the list
      calendarOrder = [calendarId, ...calendarOrder.filter((id: string) => id !== calendarId)];

      // Update Firestore
      await updateDoc(userDocRef, { calendarOrder });
      console.log('Calendar order updated');
    }
  } catch (error) {
    console.error('Error updating calendar order:', error);
    throw error;
  }
};
