import React, { useState, useEffect } from 'react';
import './header.css';
import { createCalendar, joinToCalendar, deleteCalendar, updateCalendarName, unfollowCalendar } from '../../calendarService';
import WeekdaysHeader from './WeekdaysHeader';
import Icon from '@mdi/react';
import { mdiCalendarMultipleCheck, mdiAccount, mdiLogout, mdiLightbulbOn, mdiLightbulbOutline, mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { HeaderProps } from '../../types';
import { updateUserName, updateDarkMode, updateUserVisibleCalendars } from '../../userService';

const colorOptions = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF', '#33FFF5', '#FFD133', '#FF3380'];

const Header: React.FC<HeaderProps> = ({
  userId,
  isLoggedIn,
  isAnonymous,
  onLogin,
  onLogout,
  calendars,
  calendarColors,
  setCalendarColors,
  events,
  selectedCalendars,
  setSelectedCalendars,
  calendarFromUrl,
  userName, // Passataan userName App:sta
  darkMode,  // Passataan darkMode App:sta
  setUserName, // Päivitä userName App:iin
  setDarkMode // Päivitä darkMode App:iin
}) => {
  const [newCalendarName, setNewCalendarName] = useState<string>('');
  const [isCalendarId, setIsCalendarId] = useState<boolean>(false);
  const [showCalendarList, setShowCalendarList] = useState(false);
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showMenu, setShowMenu] = useState<{ [key: string]: boolean }>({});
  const [showCalendarId, setShowCalendarId] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>(colorOptions[0]);

  // Open the calendar list if calendarFromUrl is provided
  useEffect(() => {
    if (calendarFromUrl) {
      setShowCalendarList(true);
    }
  }, [calendarFromUrl]);


  const toggleCalendarList = () => {
    setShowUserOptions(false);
    setShowCalendarList(!showCalendarList);
  };

  const toggleUserOptions = () => {
    setShowCalendarList(false);
    setShowUserOptions(!showUserOptions);
  };

  const toggleMenu = (calendarKey: string) => {
    setShowMenu((prevState) => {
      const newState = Object.keys(prevState).reduce((acc: { [key: string]: boolean }, key) => {
        acc[key] = false;
        return acc;
      }, {});
      return {
        ...newState,
        [calendarKey]: !prevState[calendarKey],
      };
    });
  };

  const handleDeleteCalendar = async (calendarId: string) => {
    if (window.confirm('Are you sure you want to delete this calendar?')) {
      try {
        await deleteCalendar(calendarId);
        setSelectedCalendars(prev => prev.filter(id => id !== calendarId));
      } catch (error) {
        console.error('Error deleting calendar:', error);
      }
    }
  };

  const handleCalendarNameChange = async (calendarId: string, newName: string) => {
    try {
      await updateCalendarName(calendarId, newName);
    } catch (error) {
      console.error('Error updating calendar name:', error);
    }
  };

  const handleSaveColor = async (calendarId: string, color: string) => {
    try {
      if (userId) {
        const updatedCalendarColors = { ...calendarColors, [calendarId]: color }; // Päivitetään paikallinen tila
        setCalendarColors(updatedCalendarColors); // Päivitä tila ennen Firestore-tallennusta
  
        await updateUserVisibleCalendars(userId, selectedCalendars, updatedCalendarColors);
        console.log('Calendar color updated:', updatedCalendarColors);
      }
    } catch (error) {
      console.error('Error saving calendar color:', error);
    }
  };

  const toggleCalendarSelection = async (calendarId: string) => {
    let updatedSelectedCalendars = [];

    if (selectedCalendars.includes(calendarId)) {
      updatedSelectedCalendars = selectedCalendars.filter((id) => id !== calendarId);
    } else {
      updatedSelectedCalendars = [...selectedCalendars, calendarId];
    }

    setSelectedCalendars(updatedSelectedCalendars);

    if (userId) {
      await updateUserVisibleCalendars(userId, updatedSelectedCalendars);
    }
  };

  const checkIfCalendarId = (value: string) => {
    const idRegex = /^[A-Za-z0-9]{20}$/;
    return idRegex.test(value);
  };

  const handleInputChange = (value: string) => {
    setNewCalendarName(value);
    setIsCalendarId(checkIfCalendarId(value));
  };

  useEffect(() => {
    setIsCalendarId(checkIfCalendarId(newCalendarName));
  }, [newCalendarName]);

  const handleCreateCalendar = async () => {
    if (newCalendarName.trim() === '' || !userId) return;
    try {
      const newCalendarId = await createCalendar(userId, newCalendarName);
      setSelectedCalendars((prevCalendars) => [...prevCalendars, newCalendarId]);
      setNewCalendarName('');
    } catch (error) {
      console.error('Error creating calendar:', error);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setShowCalendarId(true);
      setTimeout(() => setShowCalendarId(false), 3000);
    });
  };

  const handleShareClick = (calendarKey: string) => {
    const baseurl = window.location.origin;
    const shareUrl = `${baseurl}?calendar=${calendarKey}`;
    copyToClipboard(shareUrl);
  };

  const handleToggleDarkMode = async () => {
    if (!userId) return;
    setDarkMode(!darkMode);
    await updateDarkMode(userId, !darkMode);
  };

  const handleUserNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setUserName(newName);
    if (!userId) return;
    await updateUserName(userId, newName);
  };

  const handleJoinCalendar = async () => {
    if (newCalendarName.trim() === '' || !userId) return;
    try {
      const joined = await joinToCalendar(newCalendarName, userId, userName);
      if (joined) {
        setSelectedCalendars((prevCalendars) => [...prevCalendars, newCalendarName]);
      } else {
        console.error("Kalenteria ei löytynyt.");
      }
      setNewCalendarName('');
    } catch (error) {
      console.error('Error joining calendar:', error);
    }
  };

  const handleUnfollowCalendar = async (calendarId: string) => {
    if (window.confirm('Are you sure you want to unfollow this calendar?')) {
      try {
        await unfollowCalendar(calendarId, userId ?? '');
        setSelectedCalendars(prev => prev.filter(id => id !== calendarId));
      } catch (error) {
        console.error('Error unfollowing calendar:', error);
      }
    }
  };

  return (
    <div className="header">
      <div className="topRow">
        <img src={`${process.env.PUBLIC_URL}/logo_white.svg`} alt="Glendr Logo" className="logoImg" />
        <div className="logo">GLENDR</div>
        <div className="user">
          {!isLoggedIn && (
            <form onSubmit={(e) => { e.preventDefault(); onLogin(email, password); }}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit">Login</button>
            </form>
          )}

          <div className="userNameDisplay">
            <span>{userName}</span>
          </div>
          <button
            onClick={toggleUserOptions}
            className={`user-options-toggle ${showUserOptions ? 'active' : ''}`}
          >
            <Icon path={mdiAccount} size={1} />
          </button>

          {showUserOptions && (
            <ul className="user-options">
              <li>
                <label>Nimi:</label>
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <button onClick={() => handleUserNameChange({ target: { value: userName } } as React.ChangeEvent<HTMLInputElement>)}>Tallenna</button>
              </li>
              <li>
                <label>Dark Mode:</label>
                <button onClick={handleToggleDarkMode}>
                  {darkMode ? (
                    <Icon path={mdiLightbulbOutline} size={1} />
                  ) : (
                    <Icon path={mdiLightbulbOn} size={1} />
                  )}
                </button>
              </li>
              {!isAnonymous && (
                <li>
                  <button onClick={onLogout}>
                    <Icon path={mdiLogout} size={1} /> Kirjaudu ulos
                  </button>
                </li>
              )}
            </ul>
          )}
        </div>

        <div className="calendarManagement">
          <button
            onClick={toggleCalendarList}
            className={`calendar-list-toggle ${showCalendarList ? 'active' : ''}`}
          >
            <Icon path={mdiCalendarMultipleCheck} size={1} />
          </button>

          {showCalendarList && (
            <ul className="calendar-list">
              <div className='createNewCalendar'>
                <input
                  type="text"
                  placeholder="Uusi kalenteri"
                  value={newCalendarName}
                  onChange={(e) => handleInputChange(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      isCalendarId ? handleJoinCalendar() : handleCreateCalendar();
                    }
                  }}
                />
                {isCalendarId ? (
                  <button onClick={handleJoinCalendar}>Liity</button>
                ) : (
                  <button onClick={handleCreateCalendar}>Luo</button>
                )}
              </div>

              {Object.keys(calendars).map((calendarKey) => {
                const calendar = calendars[calendarKey];
                const isOwner = calendar.ownerId === userId;
                const isUrlCalendar = calendarFromUrl === calendarKey; // Check if the calendar is from URL
                const pastEventsCount = events.filter(event => event.calendarId === calendarKey && new Date(event.date) <= new Date()).length;
                const upcomingEventsCount = events.filter(event => event.calendarId === calendarKey && new Date(event.date) > new Date()).length;
                const followerCount = calendar.followers ? calendar.followers.length : 0;

                return (
                  <li key={calendarKey} className='calendarItem'>
                    <div className="calendarItemHeader">
                      <label className={`calendarItemLabel ${isOwner ? 'owner' : ''}`}>
                        <input
                          type="checkbox"
                          checked={selectedCalendars.includes(calendarKey)}
                          onChange={() => toggleCalendarSelection(calendarKey)}
                          style={{
                            appearance: 'none', // Piilotetaan oletustyyli
                            width: '1rem',      // Suurempi koko
                            height: '1rem',
                            border: `3px solid ${calendarColors[calendar.id] || '#000'}`, // Asetetaan reunuksen väri
                            borderRadius: '4px', // Kulmien pyöristys (valinnainen)
                            outline: 'none',
                            margin: '0 10px 0 0',
                            cursor: 'pointer',
                            backgroundColor: selectedCalendars.includes(calendar.id)
                              ? calendarColors[calendar.id]
                              : 'transparent', // Valitessa täyttöväri
                            transition: 'background-color 0.2s ease, border-color 0.2s ease', // Animaatio
                          }}
                        />
                        <span style={{ color: isUrlCalendar ? 'red' : 'inherit' }}>
                          {showMenu[calendarKey] ? (
                            <input
                              type="text"
                              value={calendar.name}
                              onChange={(e) => handleCalendarNameChange(calendarKey, e.target.value)}
                            />
                          ) : (
                            <span>
                              {calendar.name}
                            </span>
                          )}
                        </span>
                      </label>
                      <div
                        onClick={() => toggleMenu(calendarKey)}
                        style={{ cursor: 'pointer', display: 'inline-block' }}
                      >
                        <Icon path={showMenu[calendarKey] ? mdiChevronUp : mdiChevronDown} size={1} />
                      </div>
                    </div>

                    <div className="calendarItemInfo">
                      {showMenu[calendarKey] && (
                        <div className="dropdown-menu">

                          <div className="colorSelector">
                            <div className="colorOptions">
                              {colorOptions.map((color) => (
                                <label key={color} className="colorOption">
                                  <input
                                    type="radio"
                                    name={`color-${calendarKey}`}
                                    value={color}
                                    checked={calendarColors[calendarKey] === color}
                                    onChange={() => handleSaveColor(calendarKey, color)}
                                  />
                                  <span
                                    className={`colorPreview ${calendarColors[calendarKey] === color ? 'selected' : ''}`}
                                    style={{
                                      display: 'inline-block',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: color,
                                      borderRadius: '50%',
                                      marginLeft: '5px',
                                      cursor: 'pointer',
                                      border: calendarColors[calendarKey] === color ? '2px solid black' : '2px solid transparent',
                                    }}
                                  ></span>
                                </label>
                              ))}
                            </div>
                          </div>

                          {isOwner && (
                            <>
                              {upcomingEventsCount === 0 && (
                                <button onClick={() => handleDeleteCalendar(calendarKey)}>Poista</button>
                              )}
                              <div className="shareCalendarButton">
                                <span onClick={() => handleShareClick(calendarKey)} style={{ cursor: 'pointer' }}>
                                  JAA
                                </span>
                              </div>
                              {showCalendarId && (
                                <div>
                                  <p>{calendarKey}</p>
                                  <p>Link copied to clipboard!</p>
                                </div>
                              )}
                            </>
                          )}
                          {!isOwner && (
                            <button onClick={() => handleUnfollowCalendar(calendarKey)}>Lopeta seuraaminen</button>
                          )}
                          {/* <div className="numberOfPastEvents">
                            Past Events: {pastEventsCount}
                          </div>
                          <div className="numberOfUpcomingEvents">
                            Upcoming Events: {upcomingEventsCount}
                          </div>
                          <div className="numberOfFollowers">
                            Followers: {followerCount}
                          </div> */}
                          <div className="followersList">
                            <ul>
                              {calendar.followerData?.map((follower: any) => (
                                <li key={follower.userId}>{follower.userName}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      <WeekdaysHeader />
    </div>
  );
};

export default Header;
