import React, { useState, useEffect } from 'react';
import Calendar from './components/Calendar/Calendar';
import Header from './components/Header/Header';
import EventPanel from './components/EventPanel/EventPanel';
import { listenToCalendars, checkIfCalendarAllowed } from './calendarService';
import { listenToEventsForCalendar } from './eventService';
import { login, logout, loginAnonymously } from './authService';
import { createOrUpdateUserSettings, getUserData } from './userService';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import './global.css';
import { EventType, CalendarType } from './types';

const App: React.FC = () => {
  // Application states
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [calendars, setCalendars] = useState<{ [key: string]: CalendarType }>({});
  const [calendarStatus, setCalendarStatus] = useState<string>('');
  const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);
  const [events, setEvents] = useState<EventType[]>([]);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [prevSelectedDay, setPrevSelectedDay] = useState<string | null>(null);
  const [selectedRange, setSelectedRange] = useState<[string, string] | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [calendarOrder, setCalendarOrder] = useState<string[]>([]);
  const [calendarColors, setCalendarColors] = useState<{ [key: string]: string }>({});

  // Handle user authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        setIsLoggedIn(true);
        setIsAnonymous(user.isAnonymous);
        await createOrUpdateUserSettings(user.uid);
      } else {
        setUserId(null);
        setIsLoggedIn(false);
        setIsAnonymous(false);
      }
    });
    return () => unsubscribe();
  }, []);

  // Load calendar from URL if present
  useEffect(() => {
    const loadCalendarIfNeeded = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const calendarId = urlParams.get('calendar');

      if (calendarId && calendarId.length === 20) {
        try {
          const calendarData = await checkIfCalendarAllowed(calendarId);
          if (calendarData) {
            const fullCalendarData: CalendarType = {
              id: calendarData.id,
              name: calendarData.name || 'Untitled Calendar',
              ownerId: calendarData.ownerId || 'Unknown Owner',
              events: calendarData.events || {},
              followers: calendarData.followers || [],
              colors: undefined,
            };

            setCalendars((prev) => ({
              ...prev,
              [fullCalendarData.id]: fullCalendarData,
            }));

            setSelectedCalendars((prev) => [...prev, fullCalendarData.id]);
            setCalendarStatus(`${calendarId}`);

            listenToEventsForCalendar(fullCalendarData.id, (updatedEvents) => {
              setEvents((prevEvents) => {
                const filteredEvents = prevEvents.filter(event => event.calendarId !== fullCalendarData.id);
                return [...filteredEvents, ...updatedEvents];
              });
            });
          } else {
            setCalendarStatus('');
          }
        } catch (error) {
          console.error('Error loading calendar from URL:', error);
        }
      }
    };

    if (userId) {
      loadCalendarIfNeeded();
    }
  }, [userId]);

  // Load user data after login
  useEffect(() => {
    const loadUserData = async () => {
      try {
        if (!userId) return;
        const userData = await getUserData(userId);
        setDarkMode(userData.settings?.darkMode || false);
        setSelectedCalendars(userData.visibleCalendars || []);
        setUserName(userData.publicName || 'Guest');
        setCalendarOrder(userData.calendarOrder || []);
        setCalendarColors(userData.calendarColors || {});
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    loadUserData();
  }, [userId]);

  // Fetch calendars and set visible ones
  useEffect(() => {
    if (!userId) return;
    const unsubscribe = listenToCalendars(userId, (updatedCalendars) => {
      const calendarsObj = updatedCalendars.reduce((acc: { [key: string]: CalendarType }, calendar) => {
        acc[calendar.id] = calendar;
        return acc;
      }, {});
  
      setCalendars((prevCalendars) => {
        if (JSON.stringify(prevCalendars) !== JSON.stringify(calendarsObj)) {
          return calendarsObj;
        }
        return prevCalendars;
      });
    });
  
    return () => unsubscribe();
  }, [userId]);

  // Listen to events for selected calendars
  useEffect(() => {
    const unsubscribeListeners: (() => void)[] = [];

    if (selectedCalendars.length > 0) {
      selectedCalendars.forEach((calendarId) => {
        const unsubscribe = listenToEventsForCalendar(calendarId, (updatedEvents) => {
          setEvents((prevEvents) => {
            const filteredEvents = prevEvents.filter(event => event.calendarId !== calendarId);
            return [...filteredEvents, ...updatedEvents];
          });
        });
        unsubscribeListeners.push(unsubscribe);
      });
    }

    return () => {
      unsubscribeListeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [selectedCalendars]);

  // Handle day selection
  const handleDaySelection = (day: string | null) => {
    setPrevSelectedDay(day);
    if (day === prevSelectedDay) {
      clearSelection();
    } else {
      setSelectedDay(day);
      setSelectedRange(null);
    }
  };

  // Handle range selection
  const handleRangeSelect = (startDay: string, endDay: string) => {
    const sortedRange = [startDay, endDay].sort();
    setSelectedRange([sortedRange[0], sortedRange[1]]);
    setSelectedDay(null);
  };

  // Clear selection
  const clearSelection = () => {
    setSelectedDay(null);
    setSelectedRange(null);
  };

  // Login handler
  const handleLogin = async (email: string, password: string) => {
    try {
      await login(email, password);
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  // Logout handler
  const handleLogout = async () => {
    try {
      await logout();
      setCalendars({});
      setEvents([]);
      setSelectedCalendars([]);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Order calendars based on user preference
  const getOrderedCalendars = (): { [key: string]: CalendarType } => {
    const orderedCalendars: { [key: string]: CalendarType } = {};
    const unorderedCalendars: { [key: string]: CalendarType } = {};

    calendarOrder.forEach((id) => {
      if (calendars[id]) {
        orderedCalendars[id] = calendars[id];
      }
    });

    Object.keys(calendars).forEach((id) => {
      if (!calendarOrder.includes(id)) {
        unorderedCalendars[id] = calendars[id];
      }
    });

    return { ...orderedCalendars, ...unorderedCalendars };
  };

  return (
    <div className={`app ${darkMode ? 'dark-mode' : ''}`}>
      <Header
        userId={userId}
        isLoggedIn={isLoggedIn}
        isAnonymous={isAnonymous}
        onLogin={handleLogin}
        onLogout={handleLogout}
        calendars={getOrderedCalendars()}
        calendarColors={calendarColors}
        setCalendarColors={setCalendarColors}
        events={events}
        selectedCalendars={selectedCalendars}
        setSelectedCalendars={setSelectedCalendars}
        calendarFromUrl={calendarStatus}
        userName={userName}
        darkMode={darkMode}
        setUserName={setUserName}
        setDarkMode={setDarkMode}
      />

      <Calendar
        selectedDay={selectedDay}
        selectedRange={selectedRange}
        onDaySelect={handleDaySelection}
        onRangeSelect={handleRangeSelect}
        calendars={getOrderedCalendars()}
        events={events}
        selectedCalendars={selectedCalendars}
      />

      {userId && (
        <EventPanel
          selectedDay={selectedDay}
          selectedRange={selectedRange}
          onDayClick={handleDaySelection}
          onClearSelection={clearSelection}
          calendars={getOrderedCalendars()}
          events={events}
          userId={userId}
          userName={userName}
          selectedCalendars={selectedCalendars}
          setSelectedCalendars={setSelectedCalendars}
        />
      )}
    </div>
  );
};

export default App;
